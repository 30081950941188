#hi {
  font-family: 'Lato', sans-serif;
  font-size: 70px;
  font-weight: 900;
  margin-left: 30px;
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
  font-size: 40px;
  overflow: hidden;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

#wave-hi {
  font-size: 55px;
}

#contact-container {
  margin-top: 50px;
  margin-left: 50px;
}

form {
  margin: 30px;
  border-right: 1px solid #d9d9d9;
  padding-right: 100px;
}
  
.form-control {
  border:none;
  border-bottom: 1px solid #d9d9d9;
}

.form-group {
  margin-bottom: 50px;
}

#msg-btn {
  background-color: #f5c15d;
  font-size: 20px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
  margin-bottom: 10px;
  padding-left: 30px;
  border-radius: 30px;
  border-style: none;
  color: white;
  box-shadow: 0px 5px 5px  rgb(212 143 9 / 25%);}

#right-contact-text {
  position: absolute;
  top: 30%;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  overflow: visible;
}

#contact-bolder {
  font-weight: 900;
}

#right-contact-text a {
  text-decoration: none;
  background-color: #f5c15d;
  font-weight: 600;
  color: black;
  border-radius: 30px;
  padding: 20px;
  color: white;
  box-shadow: 0px 23px 28px -24px rgb(212 143 9 / 65%);
}

.error-text {
  font-weight: 600;
  font-size: 30px;
}

@media only screen and (max-width: 992px) {
  form {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 799px) {
  #right-contact {
    height: 500px;
  }
}

@media only screen and (max-width: 576px) {
  #contact-container {
    margin-left: 7px;
    padding: 15px;
  }

  form {  
    border-right: none;
  } 
}



