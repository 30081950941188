@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat+Subrayada:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Pangolin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Pangolin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Pangolin&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

body, html {
  height: 100%;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.options {
  font-size: 20px;
  padding-right: 150px;
  font-family: 'Kanit', sans-serif;
}

.my-name {
  font-family: 'Kanit', sans-serif;
  font-size: 50px;
  font-weight: 500;
  color: #F5C15D !important;
  cursor: pointer;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.navActive {
  border-style: solid;
}




.hero-image {
  background-image: url(/static/media/hero-graphic-2.3cdede68.png);
  background-size: contain;
  background-attachment: scroll;
  background-attachment: initial;
  background-repeat: no-repeat;
}

.hero-tagline {
  font-family: 'Kanit', sans-serif;
  font-size: 100px;
  font-weight: 800;
  margin-left: 70px;
  overflow-y: hidden;
}

.port-logo {
  height: 250px !important;
  margin-right: 10px !important;
  margin-left: 50px !important;
  margin-top: 80px !important;
}

.mobile-intro {
  display: none;
}

.type-wrap {
  margin-left: 72px;
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 40px;
  font-weight: 100;
}

.about {
  background-color: #f3f3f1;
  margin-top: 50px;
  padding-left: 15px;
}

.about p {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
}

.about span {
  font-weight: 900;
  font-size: 30px;
}

.inner-about {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.inner-about p {
  width: 30%;
  margin: 15px;
}

.inner-about img {
  width: 30%;
  margin: 15px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 25px;
}


/*Media Queries */

@media (max-width: 1200px) { 
  .inner-about img {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 1015px) { 
  .inner-about p {
    width: 100%;
  }

  .inner-about img {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 1018px) { 

  .hero-image{
    background-image: none;
  }

  .flex-hero{
   display: flex;
   justify-content: center;
  }

 .hero-tagline{
  font-family: 'Lato', sans-serif;
  font-size: 75px;
  text-align: center;
  margin-left: 0;
 }

 .type-wrap {
    display: none;
  }

  .mobile-intro {
    display: inline;
    font-family: 'Lato', sans-serif;
    text-align: center; 
  }

  .mobile-intro p {
    margin-left: 50px; 
    margin-right: 50px;
    font-size: 30px; 
    font-weight: 300;
  }

  #about-tag {
    color: #f8049c ;
  }

  .about {
  margin-top: 150px;
  }
}

@media (max-width: 680px) { 

  .about {
    font-size: 15px;
  }

  .inner-about {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 500px) { 

  .port-logo {
    height: 150px !important;
  }

  .hero-tagline {
    font-size: 60px;
  }

  .mobile-intro p {
    font-size: 20px;
  }

  .inner-about img {
    width: 150px;
    height: 150px;
  }
}


.quote {
  text-align: center;
  margin-top: 75px;
  padding-left: 100px;
  padding-right: 100px;
  font-family: 'Pangolin', cursive;
  line-height: 2.5;
}

.quote h3 {
  padding: 10px;
  font-size: 50px;
}

.quote p {
  font-size: 30px;
}

/* ======== ICON STYLING =========== */

.grid {
  position: relative;
  margin: 0 auto;
  padding: 1em 0 4em;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 320px;
  max-width: 480px;
  max-height: 360px;
  width: 31%;
  background: #3085a3;
  text-align: center;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.icon {
  height: auto;
  margin-right: 0; 
  margin-left: 0; 
  margin-top: 0;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption a {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*===============Effect============*/

figure.effect-chico img {
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
}

figure.effect-chico:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-chico figcaption {
  padding: 3em;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
}

figure.effect-chico figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.effect-chico figcaption::before,
figure.effect-chico p {
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-chico h2 {
  padding: 20% 0 20px 0;
}

figure.effect-chico p {
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*=========MEDIA QUERIES======*/

@media only screen and (max-width: 1078px) {
  .grid {
    overflow: visible;
  }
  
  .grid figure {
    width: 48%;
  }
}

@media only screen and (max-width: 667px) {
  .grid figure {
    width: 80%;
  }

  .quote {
    text-align: left;
    padding-left: 5px;
  }

  .quote h3 {
    font-size: 30px;
  }

  .quote p {
    font-size: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .portfolio {
    padding-left: 25px;
  }

  .p-title {
    font-size: 20px;
  }

}

@media only screen and (max-width: 320px) {
  .portfolio {
    padding-left: 0px;
  }
}




.resume {
  background: #222;
}

.skills {
  margin-left: 150px;
  margin-top: 190px;
}

.skills h1 {
  font-family: 'Kanit', sans-serif;
  font-size: 100px;
  font-weight: 800;
  margin-left: 70px;
  overflow-y: hidden;
  color: white;
  position: relative;
  z-index: 1;
  top: 60px;
  overflow-y: hidden;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.blurb {
  color: white;
  text-align: center;
  font-family: 'Pangolin', cursive;
  font-size: 30px;
  width: 60%;
  margin: 0 auto;
  margin-top: 50px;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  text-align: center;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 90px;
  color: white;
  font-family: 'Kanit', sans-serif;
}

.skill-gradient {
  height: 340px;
  width: 350px;
}

.resume-gradient {
  padding: 1em;
}

.resume-container {
  width: 350px;
  position: relative;
  top: 130px;
  margin: 0 auto;
}

#download-link {
  text-decoration: none;
  font-size: 20px;
}

.skill-box {
  margin: 7px;
  background-color: #222;
}

.icon {
  font-size: 70px;
}

.icon-smaller {
  font-size: 55px;
}

.icon-bigger {
  font-size: 90px;
}

.small-box {
  padding-top: 125px;
}

.hide {
  overflow-y: hidden;
}

.space-icon {
  margin-left: 10px;
}

/*BORDER GRADIENTS*/

.border-gradient-pink-yellow {
  border-image: linear-gradient(to right, #f5c15d 0%, #f92f94 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-aqua {
  border-image: linear-gradient(to right, springgreen 0%, aqua 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-violet {
  border-image: linear-gradient(to right, gold 0%, palevioletred 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-red {
  border-image: linear-gradient(to right, crimson 0%, darkorchid 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-blue {
  border-image: linear-gradient(to right, indianred 0%, lightblue 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

/* MEDIA QUERIES */
@media (max-width: 576px) { 
  .blurb {
    font-size: 20px;
    width: 90%;
  }

  .skills-container {
    display: flex;
    justify-content: center;
  }

  .resume-container {
    width: 300px;
  }

  .skills {
    margin-left: 0;
  }

  .skills h1 {
    font-size: 50px;
    margin-left: 30px;
    top: 45px;
  }
  
  .skill-gradient {
    height: 240px;
    width: 250px;
  }

  .border-gradient {
    padding-top: 40px;
    overflow-y: hidden;
  }
}




#hi {
  font-family: 'Lato', sans-serif;
  font-size: 70px;
  font-weight: 900;
  margin-left: 30px;
}

.wave {
  -webkit-animation-name: wave-animation;
          animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;        /* Change to speed up or slow down */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;  /* Never stop waving :) */
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
  font-size: 40px;
  overflow: hidden;
}

@-webkit-keyframes wave-animation {
    0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
   10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
   30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
   40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
   50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
   60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}

@keyframes wave-animation {
    0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
   10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
   30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
   40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
   50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
   60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}

#wave-hi {
  font-size: 55px;
}

#contact-container {
  margin-top: 50px;
  margin-left: 50px;
}

form {
  margin: 30px;
  border-right: 1px solid #d9d9d9;
  padding-right: 100px;
}
  
.form-control {
  border:none;
  border-bottom: 1px solid #d9d9d9;
}

.form-group {
  margin-bottom: 50px;
}

#msg-btn {
  background-color: #f5c15d;
  font-size: 20px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
  margin-bottom: 10px;
  padding-left: 30px;
  border-radius: 30px;
  border-style: none;
  color: white;
  box-shadow: 0px 5px 5px  rgb(212 143 9 / 25%);}

#right-contact-text {
  position: absolute;
  top: 30%;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  overflow: visible;
}

#contact-bolder {
  font-weight: 900;
}

#right-contact-text a {
  text-decoration: none;
  background-color: #f5c15d;
  font-weight: 600;
  color: black;
  border-radius: 30px;
  padding: 20px;
  color: white;
  box-shadow: 0px 23px 28px -24px rgb(212 143 9 / 65%);
}

.error-text {
  font-weight: 600;
  font-size: 30px;
}

@media only screen and (max-width: 992px) {
  form {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 799px) {
  #right-contact {
    height: 500px;
  }
}

@media only screen and (max-width: 576px) {
  #contact-container {
    margin-left: 7px;
    padding: 15px;
  }

  form {  
    border-right: none;
  } 
}




footer {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
}

footer h3 {
  font-size: 30px;
  font-weight: 100;
} 

footer p {
  font-size: 13px;
  font-weight: 300;
  margin-top: 20px;
}

.wave {
  font-size: 25px;
}

.connect-icons a {
  font-size: 20px;
  color: black;
  padding: 10px;
  padding-top: 0;
}

