.options {
  font-size: 20px;
  padding-right: 150px;
  font-family: 'Kanit', sans-serif;
}

.my-name {
  font-family: 'Kanit', sans-serif;
  font-size: 50px;
  font-weight: 500;
  color: #F5C15D !important;
  cursor: pointer;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.navActive {
  border-style: solid;
}



