@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Pangolin&display=swap');

.quote {
  text-align: center;
  margin-top: 75px;
  padding-left: 100px;
  padding-right: 100px;
  font-family: 'Pangolin', cursive;
  line-height: 2.5;
}

.quote h3 {
  padding: 10px;
  font-size: 50px;
}

.quote p {
  font-size: 30px;
}

/* ======== ICON STYLING =========== */

.grid {
  position: relative;
  margin: 0 auto;
  padding: 1em 0 4em;
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 320px;
  max-width: 480px;
  max-height: 360px;
  width: 31%;
  background: #3085a3;
  text-align: center;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.icon {
  height: auto;
  margin-right: 0; 
  margin-left: 0; 
  margin-top: 0;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption a {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*===============Effect============*/

figure.effect-chico img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
}

figure.effect-chico:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-chico figcaption {
  padding: 3em;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
}

figure.effect-chico figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.effect-chico figcaption::before,
figure.effect-chico p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-chico h2 {
  padding: 20% 0 20px 0;
}

figure.effect-chico p {
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-chico:hover figcaption::before,
figure.effect-chico:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*=========MEDIA QUERIES======*/

@media only screen and (max-width: 1078px) {
  .grid {
    overflow: visible;
  }
  
  .grid figure {
    width: 48%;
  }
}

@media only screen and (max-width: 667px) {
  .grid figure {
    width: 80%;
  }

  .quote {
    text-align: left;
    padding-left: 5px;
  }

  .quote h3 {
    font-size: 30px;
  }

  .quote p {
    font-size: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .portfolio {
    padding-left: 25px;
  }

  .p-title {
    font-size: 20px;
  }

}

@media only screen and (max-width: 320px) {
  .portfolio {
    padding-left: 0px;
  }
}



