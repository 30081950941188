@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Pangolin&display=swap');

.resume {
  background: #222;
}

.skills {
  margin-left: 150px;
  margin-top: 190px;
}

.skills h1 {
  font-family: 'Kanit', sans-serif;
  font-size: 100px;
  font-weight: 800;
  margin-left: 70px;
  overflow-y: hidden;
  color: white;
  position: relative;
  z-index: 1;
  top: 60px;
  overflow-y: hidden;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.blurb {
  color: white;
  text-align: center;
  font-family: 'Pangolin', cursive;
  font-size: 30px;
  width: 60%;
  margin: 0 auto;
  margin-top: 50px;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  text-align: center;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 90px;
  color: white;
  font-family: 'Kanit', sans-serif;
}

.skill-gradient {
  height: 340px;
  width: 350px;
}

.resume-gradient {
  padding: 1em;
}

.resume-container {
  width: 350px;
  position: relative;
  top: 130px;
  margin: 0 auto;
}

#download-link {
  text-decoration: none;
  font-size: 20px;
}

.skill-box {
  margin: 7px;
  background-color: #222;
}

.icon {
  font-size: 70px;
}

.icon-smaller {
  font-size: 55px;
}

.icon-bigger {
  font-size: 90px;
}

.small-box {
  padding-top: 125px;
}

.hide {
  overflow-y: hidden;
}

.space-icon {
  margin-left: 10px;
}

/*BORDER GRADIENTS*/

.border-gradient-pink-yellow {
  -moz-border-image: -moz-linear-gradient(to left, #f5c15d 0%, #f92f94 100%);
  -webkit-border-image: -webkit-linear-gradient(to left, #f5c15d 0%, #f92f94 100% );
  border-image: linear-gradient(to right, #f5c15d 0%, #f92f94 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-aqua {
  -moz-border-image: -moz-linear-gradient(to left, springgreen 0%, aqua 100%);
  -webkit-border-image: -webkit-linear-gradient(to left, springgreen 0%, aqua 100% );
  border-image: linear-gradient(to right, springgreen 0%, aqua 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-violet {
  -moz-border-image: -moz-linear-gradient(to left, gold 0%, palevioletred 100%);
  -webkit-border-image: -webkit-linear-gradient(to left, gold 0%, palevioletred 100% );
  border-image: linear-gradient(to right, gold 0%, palevioletred 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-red {
  -moz-border-image: -moz-linear-gradient(to left, crimson 0%, darkorchid 100%);
  -webkit-border-image: -webkit-linear-gradient(to left, crimson 0%, darkorchid 100% );
  border-image: linear-gradient(to right, crimson 0%, darkorchid 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

.border-gradient-blue {
  -moz-border-image: -moz-linear-gradient(to left, indianred 0%, lightblue 100%);
  -webkit-border-image: -webkit-linear-gradient(to left, indianred 0%, lightblue 100% );
  border-image: linear-gradient(to right, indianred 0%, lightblue 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}

/* MEDIA QUERIES */
@media (max-width: 576px) { 
  .blurb {
    font-size: 20px;
    width: 90%;
  }

  .skills-container {
    display: flex;
    justify-content: center;
  }

  .resume-container {
    width: 300px;
  }

  .skills {
    margin-left: 0;
  }

  .skills h1 {
    font-size: 50px;
    margin-left: 30px;
    top: 45px;
  }
  
  .skill-gradient {
    height: 240px;
    width: 250px;
  }

  .border-gradient {
    padding-top: 40px;
    overflow-y: hidden;
  }
}



