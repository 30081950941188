@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Pangolin&display=swap');

.hero-image {
  background-image: url(../images/hero-graphic-2.png);
  background-size: contain;
  background-attachment: initial;
  background-repeat: no-repeat;
}

.hero-tagline {
  font-family: 'Kanit', sans-serif;
  font-size: 100px;
  font-weight: 800;
  margin-left: 70px;
  overflow-y: hidden;
}

.port-logo {
  height: 250px !important;
  margin-right: 10px !important;
  margin-left: 50px !important;
  margin-top: 80px !important;
}

.mobile-intro {
  display: none;
}

.type-wrap {
  margin-left: 72px;
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 40px;
  font-weight: 100;
}

.about {
  background-color: #f3f3f1;
  margin-top: 50px;
  padding-left: 15px;
}

.about p {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 2;
}

.about span {
  font-weight: 900;
  font-size: 30px;
}

.inner-about {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.inner-about p {
  width: 30%;
  margin: 15px;
}

.inner-about img {
  width: 30%;
  margin: 15px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 25px;
}


/*Media Queries */

@media (max-width: 1200px) { 
  .inner-about img {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 1015px) { 
  .inner-about p {
    width: 100%;
  }

  .inner-about img {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 1018px) { 

  .hero-image{
    background-image: none;
  }

  .flex-hero{
   display: flex;
   justify-content: center;
  }

 .hero-tagline{
  font-family: 'Lato', sans-serif;
  font-size: 75px;
  text-align: center;
  margin-left: 0;
 }

 .type-wrap {
    display: none;
  }

  .mobile-intro {
    display: inline;
    font-family: 'Lato', sans-serif;
    text-align: center; 
  }

  .mobile-intro p {
    margin-left: 50px; 
    margin-right: 50px;
    font-size: 30px; 
    font-weight: 300;
  }

  #about-tag {
    color: #f8049c ;
  }

  .about {
  margin-top: 150px;
  }
}

@media (max-width: 680px) { 

  .about {
    font-size: 15px;
  }

  .inner-about {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 500px) { 

  .port-logo {
    height: 150px !important;
  }

  .hero-tagline {
    font-size: 60px;
  }

  .mobile-intro p {
    font-size: 20px;
  }

  .inner-about img {
    width: 150px;
    height: 150px;
  }
}

